@import '../../abstracts/alerts';

.mod_verband_verein_suche {
    $margin: 0.3rem;

    .formbody {
        .control-group {

            display: grid;
            grid-template-columns: 1fr;
            margin-bottom: 1rem;
            @include media('>=tablet') {
                grid-template-columns: 1fr 2fr;
            }
            label {
                font-weight: bold;
                margin-bottom: $margin;
                @include media('>=tablet') {
                    margin-bottom: 0;
                }
            }
            .controls {
                span {
                    display: block;
                    margin-bottom: $margin;
                    label {
                        display: inline;
                        font-weight: normal;
                        padding-left: 1rem;
                        margin-bottom: 0;
                        vertical-align: text-bottom;
                    }
                }
            }
        }
        .form-actions {
            display: grid;
            grid-template-columns: 1fr;
            @include media('>=tablet') {
                grid-template-columns: 1fr 1fr 1fr;
                input {
                    grid-column-start: 2;
                    grid-column-end: 3;
                }
            }
        }
    }
    .table-wrapper {
        overflow-x: auto;
        margin-left: -1rem;
        margin-right: -1rem;
        table {
            width: 100%;
            @include table-bordered(0, 0);
            @include table-striped($grayLightest, $white);
            @include table-hovered($grayLighter);
            th, td {
                padding: 0.5rem 1rem;
            }
            thead {
                th {
                    text-align: left;
                }
            }
        }
    }
}
