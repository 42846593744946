@import '../abstracts/variables';

body {
    background-color: $bodyBackgroundColor;
    color: $bodyColor;
}

#wrapper {
    box-sizing: border-box;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

#header {
    box-sizing: border-box;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 100;
    transition-property: height;
    transition-duration: $headerTransitionDuration;
    transition-timing-function: $headerTransitionTimingFunction;
    height: $calcHeaderHeight;
    @include media('>tablet', '<=fullnavigation') {
        height: $calcHeaderHeightDesktopWithoutFullNav;
    }
    @include media('>=fullnavigation') {
        height: $calcHeaderHeightDesktopWithFullNav;
    }
    &.shrinked {
        @include media('>tablet', '<=fullnavigation') {
            height: $calcHeaderHeightShrinkedWithoutFullNav;
        }
        @include media('>=fullnavigation') {
            height: $calcHeaderHeightShrinkedWithFullNav;
        }
    }

    .inside {
        background-color: $mainBackgroundColor;
        color: $mainBackgroundColor;
        box-sizing: border-box;
        position: relative;
        width: 100%;
        max-width: $maxWidth;
        margin: 0 auto;
        padding: 0;
        transition-property: all;
        transition-duration: $headerTransitionDuration;
        transition-timing-function: $headerTransitionTimingFunction;
        border-bottom: $headerBorderBottomWidth solid $primaryColor;
        @include media('>=fullnavigation') {
            border-bottom: none;
        }
        display: grid;
        grid-template-areas:
            "logo      social   buttons"
            "nav       nav      nav";
        grid-template-columns: 1fr calc(#{$headerSocialCount} * #{$headerRowHeight} + #{$headerPadding}) calc(#{$headerButtonCount} * #{$headerRowHeight} + #{$headerPadding} + #{$headerButtonCount - 1} * #{$headerButtonMargin});
        height: $calcHeaderHeight;
        @include media('>tablet', '<=fullnavigation') {
            grid-template-columns: 1fr calc(#{$headerSocialCount} * #{$headerRowHeightDesktop} + #{$headerPadding}) calc(#{$headerButtonCount} * #{$headerRowHeightDesktop} + #{$headerPadding} + #{$headerButtonCount - 1} * #{$headerButtonMargin});
            height: $calcHeaderHeightDesktopWithoutFullNav;
        }
        @include media('>=fullnavigation') {
            grid-template-columns: 1fr calc(#{$headerSocialCount} * #{$headerRowHeightDesktop} + #{$headerPadding}) calc(#{$headerButtonCountFullnavigation} * #{$headerRowHeightDesktop} + #{$headerPadding} + #{$headerButtonCountFullnavigation - 1} * #{$headerButtonMargin});
            height: $calcHeaderHeightDesktopWithFullNav;
        }
        @at-root #header {
            &.shrinked {
                .inside {
                    @include media('>tablet', '<=fullnavigation') {
                        height: $calcHeaderHeightShrinkedWithoutFullNav;
                        grid-template-columns: 1fr calc(#{$headerSocialCount} * #{$headerRowHeightShrinked} + #{$headerPadding}) calc(#{$headerButtonCount} * #{$headerRowHeightShrinked} + #{$headerPadding} + #{$headerButtonCount - 1} * #{$headerButtonMargin});
                    }
                    @include media('>=fullnavigation') {
                        height: $calcHeaderHeightShrinkedWithFullNav;
                        grid-template-columns: 1fr calc(#{$headerSocialCount} * #{$headerRowHeightShrinked} + #{$headerPadding}) calc(#{$headerButtonCountFullnavigation} * #{$headerRowHeightShrinked} + #{$headerPadding} + #{$headerButtonCountFullnavigation - 1} * #{$headerButtonMargin});
                    }
                }
            }
        }
    }
}

#before {
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    max-width: $maxWidth;
    margin: 0 auto;
    padding: $beforeVerticalPadding 0;

    .inside {
        padding: 0;
    }
}

#container {
    flex: 1;
    box-sizing: border-box;
    display: grid;
    overflow: hidden;
    width: 100%;
    max-width: $maxWidth;
    margin: 0 auto;

    grid-template-areas:
        "main";
    grid-template-columns: 1fr;

    @at-root body.col-2 #container {
        grid-template-areas:
            "main"
            "right";
        grid-template-columns: 1fr;
        grid-gap: 1rem;

        @include media('>tablet') {
            grid-template-areas:
                "main right";
            grid-template-columns: 2fr 1fr;
        }
    }
}

#main {
    grid-area: main;
    box-sizing: border-box;
    background-color: $mainBackgroundColor;
    color: $mainColor;
    overflow: hidden;

    .inside {
        padding: 1rem 1rem;
    }
}

#right {
    grid-area: right;
    box-sizing: border-box;
    background-color: $mainBackgroundColor;
    color: $mainColor;
    overflow: hidden;

    .inside {
        padding: 1rem 1rem;
    }
}

#left {
    grid-area: left;
    box-sizing: border-box;
    background-color: $mainBackgroundColor;
    color: $mainColor;
    overflow: hidden;

    .inside {
        padding: 1rem 1rem;
    }
}

#footer {
    box-sizing: border-box;
    color: $footerColor;
    overflow: hidden;

    .inside {
        padding: 0;
    }
}
