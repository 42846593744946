@import '../../abstracts/variables';
@import '../../abstracts/tables';

.mod_ltvberlin_samba_lizenzerhalt {
    .table-wrapper {
        margin: 1rem -1rem 0 -1rem;
        overflow-x: auto;
        table {
            width:100%;
            @include table-bordered(0, 0);
            @include table-striped($grayLightest, $white);
            @include table-hovered($grayLighter);
            th, td {
                padding: 0.5rem 1rem;
            }
            thead {
                th {
                    text-align: left;
                }
            }
        }
    }
}
