.mod_verband_funktion_leser {
    display: grid;
    grid-template-areas:
        "headline headline"
        "image content";
    grid-template-columns: 1fr 3fr;
    grid-gap: 1rem;

    >h1, >h2, >h3, >h4, >h5, >h6 {
        grid-area: headline;
    }

    .image_container {
        grid-area: image;
    }

    .content {
        grid-area: content;
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
        .kontakte {
            > span, > a {
                display: block;
            }
        }
    }
}
