@import './mixins';
@import './tables';

@mixin contao-tabled-form {
    .formbody {
        // TODO: contao 3 - remove after migration
        @include default-inline-table();
        table {
            width: 100%;
            th, td {
                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                }
            }
        }
        // contao 4
        .widget {
            margin-top: 0.5rem;
            &:first-of-type {
                margin-top: 0;
            }
            display: grid;
            grid-template-columns: 1fr;

            @include media('>=tablet') {
                grid-gap: 0.5rem;
                grid-template-columns: 1fr 1fr 2fr 2fr;

                label {
                    grid-column-start: 1;
                    grid-column-end: 3;
                }
                input, textarea, select, .checkbox_container {
                    grid-column-start: 3;
                    grid-column-end: 5;
                }
                .submit {
                    grid-column-start: 3;
                    grid-column-end: 4;
                }
            }
        }
    }
}
