@import '../../abstracts/variables';

#accessibility-by-gesture {
    display: none;
}

.accessibility-by-gesture-dialog {
    box-sizing: border-box;
    border: 0px solid gray;
    padding: 0;

    width: 100%;
    max-width: $maxWidth * 0.75;
    margin-block-start: $calcHeaderHeight;

    @include media('>tablet', '<=fullnavigation') {
        margin-block-start: $calcHeaderHeightDesktopWithoutFullNav;
    }
    @include media('>=fullnavigation') {
        margin-block-start: $calcHeaderHeightDesktopWithFullNav;
    }
    @at-root body.shrinked-header {
        .accessibility-by-gesture-dialog {
            @include media('>tablet', '<=fullnavigation') {
                margin-block-start: $calcHeaderHeightShrinkedWithoutFullNav;
            }
            @include media('>=fullnavigation') {
                margin-block-start: $calcHeaderHeightShrinkedWithFullNav;
            }
        }
    }

    &::backdrop {
        background: hsla(0, 0%, 0%, .25);
    }

    .controls {
        text-align: right;
        padding: 1rem;
        .close {
            margin: 0;
        }
    }

    #accessibility-by-gesture  {
        padding: 0 1rem 1rem 1rem;
        display: inherit;
    }
}
