@import '../../abstracts/variables';
@import '../../abstracts/tables';

.mod_ltvberlin_ergebnis_archiv_navigation {
    margin-bottom: 1rem;
}

.mod_ltvberlin_ergebnis_archiv {
    table {
        width: 100%;
        margin-top: 1rem;
        @include table-striped($grayLightest, $white);
        td {
            padding: 0.3rem 0.5rem;
            &.datum {
                width: 25%;
                font-weight: bold;
            }
            &.titel {
                width: 25%;
            }
            &.ergebnisse {
                a {
                    display: block;
                }
            }
        }
    }
}
