@import '../../abstracts/variables';
@import '../../abstracts/mixins';

.mod_ltvberlin_ergebnis_aktuell {
    .ergebnis_simple {
        a {
            display: block;
            background-color: $secondaryColor;
            color: $primaryColor;
            text-decoration: none;
            &:hover {
                background-color: $primaryColor;
                color: $white;
            }
            @include transition(background 0.5s ease-out);
            padding: 0.3rem 0.5rem;
            font-size: 0.9em;
            margin-bottom: 0.3rem;
        }
    }
}
