@import '../../abstracts/tables';
@import '../../abstracts/variables';

.mod_verband_gremium_liste {
    margin-left: -1rem;
    margin-right: -1rem;
    overflow-x: auto;
    table {
        width: 100%;
        @include table-bordered(0, 0);
        @include table-striped($grayLightest, $white);
        @include table-hovered($grayLighter);
        th, td {
            padding: 0.5rem 1rem;
        }
        thead {
            th {
                text-align: left;
            }
        }
    }
}
