@import '../abstracts/variables';
@import '../abstracts/mixins';

#kinderschutzsiegel, #article-463 {
    background-color: $primaryColor;
    padding: 1rem;
    margin: 1rem -1rem;

    color: $white;
    font-weight: bold;

    @include clearfix;

    .kinderschutzsiegel {
        float: left;
        padding-right: 1rem;
        width: calc(50% - 1rem);
        @include media('>phone', '<=tablet') {
            width: calc(20% - 1rem);
        }
    }
    .ce_text {
        p {
            margin-bottom: 0;
            line-height: 1.75rem;
        }
    }
}
