@import '../../abstracts/variables';

.mod_verband_vereinsdateneditor {
    .formbody {
        fieldset {
            margin-top: 1rem;
            &:first-child {
                margin-top: 0;
            }
            legend {
                font-size: 1.25rem;
                font-weight: bold;
                line-height: 1.5;
                color: $primaryColor;
            }
            .control-group {
                display: grid;
                grid-template-columns: 1fr;
                margin-top: 0.5rem;
                &:first-child {
                    margin-top: 0;
                }
                @include media('>=tablet') {
                    grid-template-columns: 1fr 2fr;
                }
                label {
                    font-weight: bolder;
                }
                .controls {
                    .checkboxgroup {
                        span {
                            display: block;
                            margin-bottom: 0.5rem;
                            label {
                                display: inline;
                                font-weight: normal;
                                padding-left: 1rem;
                                margin-bottom: 0;
                                vertical-align: text-bottom;
                            }
                        }
                    }
                }
            }
        }
        .form-actions {
            margin-top: 1rem;
            display: grid;
            grid-template-columns: 1fr;
            @include media('>=tablet') {
                grid-template-columns: 1fr 1fr 1fr;
                input {
                    grid-column-start: 2;
                    grid-column-end: 3;
                }
            }
        }
    }
}
