@import '../../abstracts/variables';

.mod_verband_funktion_anzeige {
    margin: 1rem -1rem;
    background-color: $gray;
    padding: 1rem;
    display: grid;
    grid-template-areas:
        "headline headline"
        "image content";
    grid-template-columns: 1fr 3fr;

    >h1, >h2, >h3, >h4, >h5, >h6 {
        grid-area: headline;
        color: $white !important;
    }

    .image_container {
        grid-area: image;
    }

    .content {
        margin-left: 1rem;
        grid-area: content;
        color: white;
        a {
            color: white;
        }
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
        .funktion {
            strong, em {
                display: block;
            }
        }
        .details {
            text-align: right;
            &:before {
                content: "→"
            }
        }
    }
}

.mod_article {
    &.first {
        .mod_verband_funktion_anzeige {
            margin-top: 0;
        }
    }
}
