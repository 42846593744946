@import '../../abstracts/tables';
@import '../../abstracts/variables';

.mod_verband_verein_liste {
    .table-wrapper {
        overflow-x: auto;
        margin-left: -1rem;
        margin-right: -1rem;
        table {
            width: 100%;
            @include table-bordered(0, 0);
            @include table-striped($grayLightest, $white);
            @include table-hovered($grayLighter);
            th, td {
                padding: 0.5rem 1rem;
            }
            thead {
                th {
                    text-align: left;
                }
            }
        }
    }
}
