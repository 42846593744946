@import '../abstracts/variables';

#partner, #article-462 {
    margin: 1rem -1rem;
    padding: 1rem;
    background-color: $grayLighter;
    h3 {
        color: $gray;
    }
    .ce_gallery.partner {
        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            align-content: stretch;
            li {
                flex: 1 0 auto;
                width: 50%;
                text-align: center;
                box-sizing: border-box;
                padding: 1rem;
            }
        }
    }
}
